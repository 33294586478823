<template>
    <div v-if="!isFold" class="trading-aside">
        <div class="search-input">
            <el-input v-model="keyword" prefix-icon="el-icon-search" :placeholder="$t('Trade.EnterCurrencyName')"></el-input>
            <i :class="isFold ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="isFold = !isFold"></i>
        </div>
        <el-table :data="curProductList" class="currency-table" @row-click="handleRowClick">
            <el-table-column :label="$t('Trade.Currency')">
                <template slot-scope="scope">
                    <div class="coin">
                        <img v-if="curCoinIndex == scope.row.key" class="star-icon" src="@/assets/images/pc/trade/star-on.png" />
                        <img v-else class="star-icon" src="@/assets/images/pc/trade/star-off.png" />
                        <!-- <img class="coin-icon" src="@/assets/images/pc/index/currency-demo.png" /> -->
                        <span class="coin-name">{{scope.row.name}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.Price')" sortable :sort-method="sortPrice">
                <template slot-scope="scope">
                    <span :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }">${{parseFloat(scope.row.price.close)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Trade.Change')" sortable :sort-method="sortChange">
                <template slot-scope="scope">
                    <span :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }">
                        {{parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '+' : ''}}{{((parseFloat(scope.row.price.close) - parseFloat(scope.row.price.open))/ parseFloat(scope.row.price.open) * 100).toFixed(2)}}%
                    </span>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div v-else class="unfold-aside">
        <i :class="isFold ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="isFold = !isFold"></i>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: '',
                isFold: false
            }
        },
        created() {
            this.$store.dispatch('getProduct')
        },
        mounted() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        activated() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            productList() {
                let product_list = this.$store.state.productList.filter(item => (item.perpetualAble == 1 && this.$route.path == '/PerpetualContract') || this.$route.path == '/CurrencyTrading' || (item.secondAble == 1 && this.$route.path == '/SecondContract'))
                return product_list
            },
            
            curProductList() {
                return this.productList.filter(item => item.name.includes(this.keyword.toLocaleUpperCase()))
            },
            
            curCoinIndex() {
                return this.$store.state.curCoin.key
            },
            
            curFirstProduct() {
                let product_list = [...this.productList]
                return product_list.length > 0 ? product_list[0] : null
            }
        },
        watch: {
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    this.$ws.sendMsg('{"type":"join","data":"ticker"}')
                }
            },
            
            curFirstProduct(val) {
                let find_coin = this.productList.find(item => item.key == this.curCoinIndex)
                if(!find_coin && val) {
                    this.$store.commit('setCurCoin',val)
                }
            }
        },
        methods: {
            handleRowClick(row) {
                // console.log(row)
                this.$store.commit('setCurCoin',row)
            },
            
            sortPrice(a,b) {
                return parseFloat(a.price.close) - parseFloat(b.price.close)
            },
            
            sortChange(a,b) {
                // console.log(a,b)
                let a_change = (parseFloat(a.price.close) - parseFloat(a.price.open)) / parseFloat(a.price.open)
                let b_change = (parseFloat(b.price.close) - parseFloat(b.price.open)) / parseFloat(b.price.open)
                return parseFloat(a_change) - parseFloat(b_change)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .trading-aside {
        min-width: 300px;
        max-width: 300px;
        box-sizing: border-box;
        padding: 14px 0;
        background-color: #FFFFFF;
        transition: all .1s;
        
        .search-input {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0 12px;
            
            /deep/ .el-input__inner {
                height: 35px;
                line-height: 35px;
                padding: 0 16px 0 32px;
                border: 1px solid #D3D3D3;
                font-size: 12px;
                color: #000000;
                background-color: #FFFFFF;
            }
            
            /deep/ .el-input__icon {
                line-height: 35px;
            }
        }
        
        .currency-table {
            width: 100%;
            box-sizing: border-box;
            // padding: 0 12px; 
        }
        
        /deep/ .el-table .ascending .sort-caret.ascending {
            border-bottom-color: #F08834;
        }
        
        /deep/ .el-table .descending .sort-caret.descending {
            border-top-color: #F08834;
        }
        
        /deep/ .el-table__body, 
        /deep/ .el-table__header {
            width: 100% !important;
            table-layout: auto !important;
        }
        
        /deep/ .el-table::before {
            height: 0;
        }
        
        /deep/ .el-table__row {
            cursor: pointer;
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 16px 0 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 5px;
                font-size: 12px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table td {
            padding: 10px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 5px;
                font-size: 14px;
                color: #000000;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 80px;
        
            .el-table__empty-text {
                line-height: 80px;
                font-size: 14px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        /deep/ .el-table td.el-table__cell, 
        /deep/ .el-table th.el-table__cell.is-leaf {
            border: 0;
        }
        
        .coin {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding-left: 8px;
            
            .star-icon {
                min-width: 16px;
                max-width: 16px;
                height: 16px;
                margin: 0 6px 2px 0;
            }
            
            .coin-icon {
                min-width: 19px;
                max-width: 19px;
                height: 19px;
                margin: 0 5px;
            }
            
            .coin-name {
                font-weight: bold;
            }
        }
    }
    
    .el-icon-s-fold,
    .el-icon-s-unfold {
        margin-left: 16px;
        font-size: 25px;
        color: #6D6F7E;
        white-space: nowrap;
        cursor: pointer;
    }
    
    .unfold-aside {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 40px;
        max-width: 40px;
        box-sizing: border-box;
        padding: 14px 0;
        background-color: #FFFFFF;
        transition: all .1s;
        
        .el-icon-s-fold,
        .el-icon-s-unfold {
            margin-left: 0 !important;
        }
    }
</style>